import React, { useState, useEffect } from "react"
import { camelizeKeys, decamelizeKeys } from "humps"

import NutritionPlanFoodDistributionQuantityTable from "./partials/NutritionPlanFoodDistributionQuantityTable"
import NutritionPlanMacronutrientsTable from "./partials/NutritionPlanMacronutrientsTable"
import NutritionPlanFoodDistributionByTimeTable from "./partials/NutritionPlanFoodDistributionByTimeTable"
import NutritionPlanDietaryRestrictions from "./partials/NutritionPlanDietaryRestrictions"

const KcalPerGram = {
  carbs: 4,
  proteins: 4,
  fat: 9,
}

const NutritionPlanBuilder = () => {
  window.gon = camelizeKeys(gon)

  const { leanWeight, weight, nutritionPlanStructure } = gon

  const [nutritionPlan, setNutritionPlan] = useState(nutritionPlanStructure)
  const [activeTab, setActiveTab] = useState("nutritionPlan")

  const macronutrientData = (macronutrient, percentage) => {
    const kcalPerGram = KcalPerGram[macronutrient]
    const kcal = (percentage / 100) * nutritionPlan.calorieRestriction
    const gr = kcal / kcalPerGram
    const grPerKg = gr / weight

    return {
      percentage,
      kcal,
      gr,
      grPerKg
    }
  }

  const macronutrients = {
    carbs: macronutrientData("carbs", nutritionPlan.macronutrients.carbs),
    proteins: macronutrientData("proteins", nutritionPlan.macronutrients.proteins),
    fat: macronutrientData("fat", nutritionPlan.macronutrients.fat),
  }

  const carbLoadMacronutrients = {
    carbs: macronutrientData("carbs", nutritionPlan.carbLoadMacronutrients.carbs),
    proteins: macronutrientData("proteins", nutritionPlan.carbLoadMacronutrients.proteins),
    fat: macronutrientData("fat", nutritionPlan.carbLoadMacronutrients.fat),
  }

  const handleCaloriesPerKgChange = (event) => {
    const caloriesPerKg = event.target.value

    setNutritionPlan({
      ...nutritionPlan,
      caloriesPerKg,
      calorieRestriction: (leanWeight * caloriesPerKg).toFixed(),
    })
  }

  const handleObjectiveChange = (event) => {
    const objective = event.target.value

    setNutritionPlan({
      ...nutritionPlan,
      objective,
    })
  }

  const handleNavigationTabChange = (event) => {
    event.preventDefault()
    const activeTab = event.target.name
    setActiveTab(activeTab)
  }

  useEffect(() => {
    if (!nutritionPlan.carbLoad) {
      setActiveTab("nutritionPlan")
    }
  }, [nutritionPlan.carbLoad])

  const handleCarbLoadCarbsPerKgChange = (event) => {
    const carbLoadCarbsPerKg = event.target.value

    setNutritionPlan({
      ...nutritionPlan,
      carbLoadCarbsPerKg,
    })
  }

  const handleDietaryRestrictionsChange = (dietaryRestrictions) => {
    setNutritionPlan({
      ...nutritionPlan,
      dietaryRestrictions,
    })
  }

  const handleCarbLoadChange = (event) => {
    const { checked } = event.target

    setNutritionPlan({
      ...nutritionPlan,
      carbLoad: checked,
    })
  }

  const handleCalorieRestrictionChange = (event) => {
    const calorieRestriction = event.target.value
    setNutritionPlan({
      ...nutritionPlan,
      calorieRestriction,
      caloriesPerKg: (event.target.value / leanWeight).toFixed()
    })
  }

  const handleMacronutrientsChange = (newMacronutrients) => {
    setNutritionPlan({
      ...nutritionPlan,
      macronutrients: {
        ...nutritionPlan.macronutrients,
        ...newMacronutrients
      }
    })
  }

  const handleCarbLoadMacronutrientsChange = (newMacronutrients) => {
    setNutritionPlan({
      ...nutritionPlan,
      carbLoadMacronutrients: {
        ...nutritionPlan.carbLoadMacronutrients,
        ...newMacronutrients
      }
    })
  }

  const handleFoodDistributionChange = (foodDistribution) => {
    setNutritionPlan({
      ...nutritionPlan,
      foodDistribution,
    })
  }

  const handleCarbLoadFoodDistributionChange = (carbLoadFoodDistribution) => {
    setNutritionPlan({
      ...nutritionPlan,
      carbLoadFoodDistribution,
    })
  }

  const handleSaveNutritionPlan = () => {
    fetch(location.href, {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(decamelizeKeys({ nutritionPlanStructure: nutritionPlan }))
    })
    .then(response => response.json)
    .then(data => alert("Plan Guardado"))
    .catch(() => alert("Hubo un error guardando el plan"))
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row pb-3 bg-light">
          <div className="col-12 my-3 text-center">
            <div className="d-flex align-items-center justify-content-center mb-4">
              <label htmlFor="objective" className="mb-0">Objetivo:</label>
              <input
                type="text"
                className="ml-3 form-control"
                id="objective"
                value={nutritionPlan.objective}
                onChange={handleObjectiveChange}
                style={{maxWidth: "300px"}} />
            </div>

            <NutritionPlanDietaryRestrictions
              restrictions={nutritionPlan.dietaryRestrictions}
              onRestrictionsChange={handleDietaryRestrictionsChange}
            />

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="carbLoad"
                name="carbLoad"
                onChange={handleCarbLoadChange}
                checked={nutritionPlan.carbLoad}
              />
              <label className="form-check-label" htmlFor="carbLoad">Carga de Glucógeno</label>
            </div>
          </div>

          <hr className="w-100 pt-1 bg-primary"/>

          { nutritionPlan.carbLoad && (
            <React.Fragment>

              <div className="col-12">
                <ul className="nav nav-pills nav-fill">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${activeTab == "nutritionPlan" ? "active" : ""}`}
                      href=""
                      name="nutritionPlan"
                      onClick={handleNavigationTabChange}>
                        Plan de Alimentación
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${activeTab == "sportNutrition" ? "active" : ""}`}
                      href=""
                      name="sportNutrition"
                      onClick={handleNavigationTabChange}>
                        Nutrición Deportiva
                    </a>
                  </li>
                </ul>
              </div>

              <hr className="w-100 pt-1 bg-primary"/>
            </React.Fragment>
          )}

          { activeTab === "nutritionPlan" && (
            <React.Fragment>
              <div className="col-12 col-sm-6 col-lg-4">
                <table className="table table-sm table-dark table-striped col-12">
                  <tbody>
                    <tr>
                      <th>Peso total (kg)</th>
                      <td className="text-right">{ weight.toFixed(2) }</td>
                    </tr>
                    <tr>
                      <th>Peso magro (kg)</th>
                      <td className="text-right">{ leanWeight.toFixed(2) }</td>
                    </tr>
                    <tr>
                      <th>Kcal</th>
                      <td className="text-right">
                        <input
                          className="text-right bg-dark text-light border-white pr-1"
                          value={(parseFloat(nutritionPlan.caloriesPerKg) || 0).toFixed()}
                          onChange={handleCaloriesPerKgChange}
                          style={{maxWidth: "30px"}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>RE Calórica</th>
                      <td className="text-right">
                        <input
                          className="text-right bg-dark text-light border-white pr-1"
                          value={nutritionPlan.calorieRestriction}
                          onChange={handleCalorieRestrictionChange}
                          style={{maxWidth: "45px"}}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <NutritionPlanMacronutrientsTable
                  weight={weight}
                  macronutrients={macronutrients}
                  calorieRestriction={nutritionPlan.calorieRestriction}
                  onMacronutrientsChange={handleMacronutrientsChange}
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-8">
                <NutritionPlanFoodDistributionQuantityTable
                  foodDistribution={nutritionPlan.foodDistribution}
                  macronutrients={macronutrients}
                  calorieRestriction={nutritionPlan.calorieRestriction}
                  onFoodDistributionChange={handleFoodDistributionChange}
                />
              </div>

              <div className="col-12">
                <NutritionPlanFoodDistributionByTimeTable
                  foodDistribution={nutritionPlan.foodDistribution}
                  onFoodDistributionChange={handleFoodDistributionChange}
                />
              </div>
            </React.Fragment>
          )}


          { activeTab === "sportNutrition" && (
            <React.Fragment>
              <div className="col-12 col-sm-6 col-lg-4">
                <NutritionPlanMacronutrientsTable
                  weight={weight}
                  macronutrients={carbLoadMacronutrients}
                  calorieRestriction={nutritionPlan.calorieRestriction}
                  onMacronutrientsChange={handleCarbLoadMacronutrientsChange}
                />

                <table className="table table-sm table-dark table-striped col-12">
                  <tbody>
                    <tr>
                      <td>CHO/Kg</td>
                      <td className="text-right">
                        <input
                          className="text-right bg-dark text-light border-white pr-1"
                          value={nutritionPlan.carbLoadCarbsPerKg}
                          onChange={handleCarbLoadCarbsPerKgChange}
                          style={{ maxWidth: "30px" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-12 col-sm-6 col-lg-8">
                <NutritionPlanFoodDistributionQuantityTable
                  foodDistribution={nutritionPlan.carbLoadFoodDistribution}
                  macronutrients={carbLoadMacronutrients}
                  calorieRestriction={nutritionPlan.calorieRestriction}
                  onFoodDistributionChange={handleCarbLoadFoodDistributionChange}
                />
              </div>

              <div className="col-12">
                <NutritionPlanFoodDistributionByTimeTable
                  foodDistribution={nutritionPlan.carbLoadFoodDistribution}
                  onFoodDistributionChange={handleCarbLoadFoodDistributionChange}
                />
              </div>
            </React.Fragment>
          )}

          <hr className="w-100 pt-1 bg-primary"/>

          <div className="col text-center">
            <button className="btn btn-primary text-light" onClick={handleSaveNutritionPlan}>
              Guardar Estructura de Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NutritionPlanBuilder
