import React from "react"

const FoodGroupByTimeRow = ({ foodGroup, onFoodGroupChange }) => {
  const handleFoodGroupChange = (event) => {
    onFoodGroupChange({ ...foodGroup, [event.target.name]: parseFloat(event.target.value) || "" })
  }

  return(
    <tr>
      <td>{foodGroup.name}</td>
      <td>{foodGroup.quantity}</td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.preBreakfast || ""} name="preBreakfast" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.breakfast || ""} name="breakfast" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.morningSnack1 || ""} name="morningSnack1" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.morningSnack2 || ""} name="morningSnack2" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.lunch || ""} name="lunch" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.afternoonSnack1 || ""} name="afternoonSnack1" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.afternoonSnack2 || ""} name="afternoonSnack2" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.dinner || ""} name="dinner" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
      <td className="text-center">
        <input className="text-center" value={foodGroup.eveningSnack || ""} name="eveningSnack" onChange={handleFoodGroupChange} style={{maxWidth: "30px"}}/>
      </td>
    </tr>
  )
}

export default FoodGroupByTimeRow
