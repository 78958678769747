import React from "react"
import FoodGroupByTimeRow from "./FoodGroupByTimeRow"

const NutritionPlanFoodDistributionByTimeTable = ({ foodDistribution, onFoodDistributionChange }) => {
  const handleFoodGroupChange = (newFoodGroup) => {
    const newDistribution = foodDistribution.map(foodGroup => {
      return foodGroup.id == newFoodGroup.id ? newFoodGroup : foodGroup
    })
    onFoodDistributionChange(newDistribution)
  }

  return(
    <table className="table table-sm">
      <thead className="thead-dark">
        <tr>
          <th>Alimento</th>
          <th>Porciones Diarias</th>
          <th className="text-center">P</th>
          <th className="text-center">D</th>
          <th className="text-center">M</th>
          <th className="text-center">M</th>
          <th className="text-center">A</th>
          <th className="text-center">M</th>
          <th className="text-center">M</th>
          <th className="text-center">C</th>
          <th className="text-center">M</th>
        </tr>
      </thead>
      <tbody>
        { foodDistribution.map(foodGroup => (
          <FoodGroupByTimeRow
            key={foodGroup.id}
            foodGroup={foodGroup}
            onFoodGroupChange={handleFoodGroupChange}
          />
        ))}
      </tbody>
    </table>
  )
}

export default NutritionPlanFoodDistributionByTimeTable
