import React from "react"
import { camelizeKeys } from "humps"

const FoodGroupRow = ({ foodGroup, onFoodTypeChange, onQuantityChange }) => {
  const { foodTypes } = gon
  const foodTypesForFoodGroup = foodTypes.filter(ft => ft.foodGroupId == foodGroup.id)
  const foodType = foodTypes.find(ft => ft.id == foodGroup.foodTypeId) || foodTypes.find(ft => ft.foodGroupId == foodGroup.id)
  const needsSelect = foodTypesForFoodGroup.length > 1

  const handleFoodTypeChange = (event) => {
    onFoodTypeChange(foodGroup.id, event.target.value)
  }

  const handleQuantityChange = (event) => {
    onQuantityChange(foodGroup.id, event.target.value)
  }

  return (
    <tr>
      <td>
        { needsSelect ? (
          <select value={foodType.id} onChange={handleFoodTypeChange} className="custom-select" style={{maxWidth: "140px"}}>
            { foodTypesForFoodGroup.map(ft => (
              <option key={ft.id} value={ft.id}>{ft.name}</option>
            ))}
          </select>
        ) : (
          foodType.name
        )}
      </td>
      <td>
        <input value={foodGroup.quantity} onChange={handleQuantityChange} style={{maxWidth: "30px"}}/>
      </td>
      <td>{foodGroup.carbs}</td>
      <td>{foodGroup.proteins}</td>
      <td>{foodGroup.fat}</td>
      <td>{foodGroup.kcal}</td>
    </tr>
  )
}

export default FoodGroupRow
