import React from "react"

const NutritionPlanMacronutrientsTable = ({ weight, macronutrients, calorieRestriction, onMacronutrientsChange }) => {
  const { carbs, proteins, fat } = macronutrients

  const macronutrientTotal = {
    percentage: carbs.percentage + proteins.percentage + fat.percentage,
    kcal: carbs.kcal + proteins.kcal + fat.kcal,
    gr: carbs.gr + proteins.gr + fat.gr,
    grPerKg: carbs.grPerKg + proteins.grPerKg + fat.grPerKg,
  }

  const handleMacronutrientPercentageChange = (event) => {
    const percentage = parseFloat(event.target.value) || 0
    const macronutrient = event.target.dataset.macronutrient

    onMacronutrientsChange({
      [macronutrient]: percentage
    })
  }

  return(
    <table className="table table-sm col-12">
      <thead className="thead-dark">
        <tr>
          <th></th>
          <th>%</th>
          <th>kcal</th>
          <th>gr</th>
          <th>gr/kg</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Carbohidratos</th>
          <td>
            <input value={carbs.percentage} data-macronutrient="carbs" onChange={handleMacronutrientPercentageChange} style={{ maxWidth: "30px" }}/>
          </td>
          <td>{ carbs.kcal.toFixed() }</td>
          <td>{ carbs.gr.toFixed() }</td>
          <td>{ carbs.grPerKg.toFixed(1) }</td>
        </tr>
        <tr>
          <th>Proteínas</th>
          <td>
            <input value={proteins.percentage} data-macronutrient="proteins" onChange={handleMacronutrientPercentageChange} style={{ maxWidth: "30px" }}/>
          </td>
          <td>{ proteins.kcal.toFixed() }</td>
          <td>{ proteins.gr.toFixed() }</td>
          <td>{ proteins.grPerKg.toFixed(1) }</td>
        </tr>
        <tr>
          <th>Lípidos</th>
          <td>
            <input value={fat.percentage} data-macronutrient="fat" onChange={handleMacronutrientPercentageChange} style={{ maxWidth: "30px" }}/>
          </td>
          <td>{ fat.kcal.toFixed() }</td>
          <td>{ fat.gr.toFixed() }</td>
          <td>{ fat.grPerKg.toFixed(1) }</td>
        </tr>
        <tr className="bg-secondary text-white">
          <th>Total</th>
          <td>
            { macronutrientTotal.percentage.toFixed() }
          </td>
          <td>{ macronutrientTotal.kcal.toFixed()  }</td>
          <td>{ macronutrientTotal.gr.toFixed()  }</td>
          <td>{ macronutrientTotal.grPerKg.toFixed(1)  }</td>
        </tr>
      </tbody>
    </table>
  )
}

export default NutritionPlanMacronutrientsTable
