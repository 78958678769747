import React from "react"

const allRestrictions = [
  { id: "unrestricted", name: "Sin restricción"   },
  { id: "vegetarian",   name: "Vegetariano"       },
  { id: "vegan",        name: "Vegano"            },
  { id: "gluten_free",  name: "Libre de Gluten"   },
  { id: "lactose_free", name: "Libre de Lactosa"  },
  { id: "fodmap",       name: "Fodmap"            },
]

const NutritionPlanDietaryRestrictions = ({ restrictions, onRestrictionsChange }) => {
  const handleRestrictionChange = (event) => {
    const { name: restriction, checked } = event.target
    if (checked) {
      onRestrictionsChange(restrictions.concat(restriction))
    } else {
      onRestrictionsChange(restrictions.filter(e => e !== restriction ))
    }
  }

  return(
    <div>
      { allRestrictions.map(restriction => (
        <div className="form-check form-check-inline" key={restriction.id}>
          <input
            className="form-check-input"
            type="checkbox"
            id={restriction.id}
            name={restriction.id}
            onChange={handleRestrictionChange}
            checked={restrictions.includes(restriction.id)}
          />
          <label className="form-check-label" htmlFor={restriction.id}>{restriction.name}</label>
        </div>
      ))}
    </div>
  )
}

export default NutritionPlanDietaryRestrictions
