import React from "react"
import FoodGroupRow from "./FoodGroupRow"

const NutritionPlanFoodDistributionQuantityTable = ({ foodDistribution, macronutrients, calorieRestriction, onFoodDistributionChange }) => {
  const { foodTypes } = gon

  const totals = {
    quantity: foodDistribution.reduce((acum, foodGroup) => acum + parseFloat(foodGroup.quantity) || 0, 0),
    carbs: foodDistribution.reduce((acum, foodGroup) => acum + foodGroup.carbs, 0),
    proteins: foodDistribution.reduce((acum, foodGroup) => acum + foodGroup.proteins, 0),
    fat: foodDistribution.reduce((acum, foodGroup) => acum + foodGroup.fat, 0),
    kcal: foodDistribution.reduce((acum, foodGroup) => acum + foodGroup.kcal, 0),
  }

  const handleFoodGroupQuantityChange = (id, quantity) => {
    const newDistribution = foodDistribution.map(foodGroup => {
      if (foodGroup.id == id) {
        const foodType = foodTypes.find(e => e.id === foodGroup.foodTypeId)

        return {
          ...foodGroup,
          quantity,
          carbs: quantity * foodType.carbs,
          proteins: quantity * foodType.proteins,
          fat: quantity * foodType.fat,
          kcal: quantity * foodType.kcal,
        }
      } else {
        return foodGroup
      }
    })

    onFoodDistributionChange(newDistribution)
  }

  const handleFoodGroupFoodTypeChange = (id, foodTypeId) => {
    const newDistribution = foodDistribution.map(foodGroup => {
      if (foodGroup.id == id) {
        const foodType = foodTypes.find(e => e.id === foodTypeId)
        const { quantity } = foodGroup

        return {
          ...foodGroup,
          foodTypeId,
          carbs: quantity * foodType.carbs,
          proteins: quantity * foodType.proteins,
          fat: quantity * foodType.fat,
          kcal: quantity * foodType.kcal,
        }
      } else {
        return foodGroup
      }
    })

    onFoodDistributionChange(newDistribution)
  }

  return(
    <table className="table table-sm">
      <thead className="thead-dark">
        <tr>
          <th>Alimento</th>
          <th>Cantidad</th>
          <th>CHO</th>
          <th>Prot</th>
          <th>Gr</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        { foodDistribution.map(foodGroup => (
          <FoodGroupRow
            key={foodGroup.id}
            foodGroup={foodGroup}
            onFoodTypeChange={handleFoodGroupFoodTypeChange}
            onQuantityChange={handleFoodGroupQuantityChange}
          />
        ))}

        <tr className="bg-secondary text-light">
          <th>Total</th>
          <td>{totals.quantity}</td>
          <td>{totals.carbs}</td>
          <td>{totals.proteins}</td>
          <td>{totals.fat}</td>
          <td>{totals.kcal}</td>
        </tr>

        <tr className="bg-light">
          <th className="text-uppercase">% de adecuación</th>
          <td></td>
          <td>{((totals.carbs / macronutrients.carbs.gr * 100) || 0).toFixed()}%</td>
          <td>{((totals.proteins / macronutrients.proteins.gr * 100) || 0).toFixed()}%</td>
          <td>{((totals.fat / macronutrients.fat.gr * 100) || 0).toFixed()}%</td>
          <td>{((totals.kcal / calorieRestriction * 100) || 0).toFixed()}%</td>
        </tr>
      </tbody>
    </table>
  )
}

export default NutritionPlanFoodDistributionQuantityTable
